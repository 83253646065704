<template>
<div v-if="!isMobile">
    <LoadingForum v-if="isViewSwitching" ref="viewSwitchLoader" @loaded="handleViewSwitchLoaded" :message="viewSwitchMessage" class="full-screen-loader" />
    <div v-if="_g_UserFeatures.has_active_role">
        <div v-if="_g_ToggleBetweenTableAndChart">
            <div v-if="is_a_search" class="title-searches">
              <span class="search-text">{{search_text}}</span><span class="material-icons-outlined close-searches" @click="_closeSearch()">close</span>
            </div>
            <div class="main-sidebar-left">
                <Finder/>
                <div class="stocks-container">
                    <Stocks />
                </div>            
            </div>
            <!-- <img v-if="this._g_User.is_asistente_dif" src="/sites/all/themes/Porto_sub/img/banner-big.png" class="banner-big-bank"> -->
            <div class="mdc-layout-grid__inner sidebar-menu-margin" id="dragable-container">
                <div v-if="_g_SelectedTicker.chart" class="mdc-layout-grid__cell--span-12 stockchart-chart">                   
                    <div v-if="isIframeLoading" class="iframe-loading-indicator">
                      <div class="wave"></div>
                      <div class="wave"></div>
                      <div class="wave"></div>
                    </div>
                    <iframe :src="url_stock" width="98.55%" frameborder="0" id="iframe" style="height: 100%;" 
                      :class="{'chart-with-search-tittle': is_a_search, 'iframe-loading': isIframeLoading, 'iframe-loaded': !isIframeLoading}"
                      @load="handleIframeLoad"></iframe>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="main-sidebar-left">
                <Finder/>
            </div>
            <div class="mdc-layout-grid__inner sidebar-menu-margin-stocks-table" id="dragable-container">
                <div class="mdc-layout-grid__cell--span-12">
                    <StocksTable />
                </div>
            </div>
        </div>
    </div>

    <div v-else>
        <div v-if="_g_ToggleBetweenTableAndChart">
            <div class="main-sidebar-left">
                <Finder />
                <div class="stocks-container">
                    <Stocks />
                </div>            
            </div>
            <div class="mdc-layout-grid__inner sidebar-menu-margin" id="dragable-container">
                <div class="mdc-layout-grid__cell--span-12 stockchart-chart">
                    <div @keyup.enter="_searchStocks()" class="vanilla-input-container">
                        <input 
                          type="text" 
                          id="searcher-tickers" 
                          v-model="ticker_url" 
                          placeholder="Enter ticker..." 
                          class="vanilla-ticker-input"
                        />
                    </div>
                    <iframe :src="url_anonymous" width="98.55%" frameborder="0" id="iframe" style="height: 98%;"
                      @load="handleIframeLoad"></iframe>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="main-sidebar-left">
                <Finder />
            </div>
            <div class="mdc-layout-grid__inner sidebar-menu-margin-stocks-table" id="dragable-container">
                <div class="mdc-layout-grid__cell--span-12">
                    <StocksTable />
                </div>
            </div>
        </div>
    </div>
</div>

<div v-else style="height: 100%;">
  <div v-if="_g_UserFeatures.has_active_role" style="height: 100%;">
    <div v-if="!_g_option_mobile" style="height: 100%;">
      <div v-if="isIframeLoading" class="iframe-loading-indicator">
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
      </div>
      <iframe :src="url_stock" width="100%" frameborder="0" id="mobile-iframe" style="height: 100%;"
        :class="{'iframe-loading': isIframeLoading, 'iframe-loaded': !isIframeLoading}"
        @load="handleIframeLoad"></iframe>
    </div>
  </div>
  <div v-else style="height: 100%;">
    <div class="mdc-layout-grid__cell--span-12 anonymous-stockchart-chart" style="height: 100%; overflow-y: scroll;">
        <div @keyup.enter="_searchStocks()">
          <InputTextOutline id="searcher-tickers" style="width: 15%; margin-left: 6px; margin-top: 5px;" @_emit_method_order_input="_getValueFromInput" ref="getInputMontanteInferior" :permission="true" :character_counter_permission="false" :icon_permission="true" :helper_permission="true" input_key_to_save_user_values="ticker" input_class_character_counter="ticker-counter" icon_class="input-icon" icon_name="" input_label="" input_helper="" :has_focus="false" :length="32" computed_value="MSFT" :computed_value_permission="true" :test_field_is_numeric="false" />
        </div>
        <iframe :src="url_anonymous" width="100%" frameborder="0" id="mobile-iframe" style="height: 100%;"
          @load="handleIframeLoad"></iframe>
      </div>
  </div>
</div>
</template>


<script>
import Stocks from '@/components/stockchart/components/Stocks.vue'
import Finder from '@/components/stockchart/components/Finder/Finder-Base.vue'
import StocksTable from '@/components/stockchart/components/StocksTable.vue'
import InputTextOutline from "@/components/helpers/InputTextOutline.vue";
import { mapMutations, mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import GetStocks from "@/components/codeComponents/GetStocks.js";
import LoadingForum from '@/components/stockchart/components/LoadingForum.vue';

//import Chart from '@/components/TVChartContainer.vue' para importar el tradingview descomentamos esta linea

import { isMobile } from 'mobile-device-detect';
import { isTablet } from 'mobile-device-detect';

export default {
  mixins: [APICaller, GetStocks],
  name: 'Stockchart',
  components: {
    Finder,
    Stocks,
    StocksTable,
    InputTextOutline,
    LoadingForum,
    //Chart, descomentamos tambien esta linea
  },
  data: () => ({
      isMobile: window.innerWidth < 901,
      symbol: "",
      url_anonymous: "https://www.stonicks.com/embed/?c=4E6E82&sc=logaritmic&s=MSFT",
      ticker_url: "",
      url_stock: "",
      stocks: [],
      selected_ticker: '',
      search_text: "",
      is_a_search: false,
      modeOfStocks: '',
      myWidth: 0,
      isIframeLoading: true,
      lastUrlStock: "",
      lastUrlAnonymous: "",
      areStocksLoaded: false,
      isViewSwitching: false,
      viewSwitchMessage: "Cambiando vista...",
      previousToggleState: null,
      viewSwitchTimeout: null
  }),
  mounted() {
    this.myWidth = window.width;
    this._searchTitle()
    this._changeModeToStockMarket();
    var mode_waiting = setInterval(() => {
      if(this.modeOfStocks != ''){
        if(this._g_IsUserLogged){
          this.getStocks(() => {
            // Mark stocks as loaded but don't emit event yet
            this.areStocksLoaded = true;
            this._checkAllLoaded();
          });
          clearInterval(mode_waiting); 
        }
      }
    }, 200);
    this.tickerChangerWatcher();

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })

    // Initialize the previous toggle state
    this.previousToggleState = this._g_ToggleBetweenTableAndChart;
  },
  //////////////////////////////////////////////////////////////////////////////////////////////////
  watch:{
    filters: {
      hadler: function(){
        process.env.VUE_APP_API + '/stockchart?t=23&s=' + this._g_SelectedTicker.ticker;
      },
    deep: true
    },

    _g_SelectedTicker: {
      handler: function(){
        this._url_stock();
      }
    },

    _g_stocks: {
      handler: function(newVal, oldVal){
        this.stocks = newVal;
      },
      deep: true
    },
    
    url_stock: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal && newVal !== this.lastUrlStock) {
          this.isIframeLoading = true;
          this.lastUrlStock = newVal;
        }
      }
    },
    
    url_anonymous: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal && newVal !== this.lastUrlAnonymous) {
          this.isIframeLoading = true;
          this.lastUrlAnonymous = newVal;
        }
      }
    },
    
    _g_ToggleBetweenTableAndChart: {
      handler: function(newVal, oldVal) {
        if (this.previousToggleState !== null && newVal !== this.previousToggleState) {
          this.handleViewSwitch();
        }
        this.previousToggleState = newVal;
      }
    }
  },
  /////////////////////////////////////////////////////////////////////////////////////////////
  computed: {
    ...mapGetters(['_g_ToggleBetweenTableAndChart']),
    ...mapGetters({_g_stocks:'_g_Stocks'}),
    ...mapGetters(['_g_SelectedTicker', "_g_IsUserLogged", "_g_option_mobile", "_g_User", "_g_UserFeatures"]),
  },
  methods: {
    ...mapMutations(["setSelectedTicker", "setStocks"]),
    
    handleIframeLoad() {
      // Get the appropriate iframe based on whether we're displaying the anonymous or logged-in view
      const iframe = this._g_IsUserLogged ? 
        document.getElementById("iframe") : 
        document.getElementById("mobile-iframe");
      
      // Only process the load event if we have a valid iframe with src
      if (iframe && iframe.src && iframe.src.trim() !== '') {
        // Set a small timeout to ensure the iframe content is fully rendered
        setTimeout(() => {
          this.isIframeLoading = false;
          this._checkAllLoaded();
        }, 400);
      }
    },
    
    _checkAllLoaded() {
      // Only emit the stocks-loaded event when both stocks and iframe are loaded
      if (this.areStocksLoaded && !this.isIframeLoading) {
        this.$emit('stocks-loaded');
        // Also finish view switch loading if it's active
        if (this.isViewSwitching) {
          this.finishViewSwitchLoading();
        }
      }
    },
    
    onResize() {
      this.isMobile = window.innerWidth < 901
      if(window.innerWidth < 901 && this.myWidth != window.width){
        window.location.reload()
      }
    },
    _getValueFromInput(value){
        this.ticker_url = value[0]
    },
    _searchStocks: function(){
      this.url_anonymous = "https://www.stonicks.com/embed/?c=4E6E82&sc=logaritmic&s=" + this.ticker_url
    },

    _closeSearch: function(){
      window.sessionStorage.removeItem('ticker_search_by_user');
      this.$router.push('/app/graficador-dinamico');
    },
    _searchTitle: function(){
      if(this.$route.params.param1 == undefined){
        this.is_a_search =  false;
      }else{
        this.is_a_search = true;
        let param_1 = this.$route.params.param1;
        let param_2 = this.$route.params.param2;
        const dictSubSearch = {
          'last_week': "última semana",
          'last_month': "último mes",
          'last_three_months': "últimos 3 meses",
          'last_year': "último año",
          'Energy': "Energía",
          'Materials': "Materiales",
          'Utilities': "Utilidades",
          'Financials': "Finanzas",
          'Consumer Staples': "Productos de 1ª necesidad",
          'Real State': "Inmobiliario",
          'Comunication Services': "Comunicación",
          'Industrials': "Industrial",
          'Consumer Discretionary': "Consumo discrecional",
          'Health Care': "Salud",
          'Information Technology': "Tecnologías de la información",
        };
        const dictSearches = {
          "last-ipos": "Últimas IPOs",
          "best-bullish-values": "Valores alcistas " + dictSubSearch[param_2],
          "hot-values": "Más comentados " + dictSubSearch[param_2],
          "exchange": "Índice " + param_2,
          "sector":"Sector " + dictSubSearch[param_2],
          "platinum": "Valores Platino",
          "favorites": param_2,
          "twin-souls": "Almas gemelas",
          "ticker": "Ticker " + param_2,
          "waitings": "Valores en espera",
          "open-positions": "Posiciones abiertas",
          "all-values": "Valores en la cartera",
          "best-sectors": "Mejores sectores " + dictSubSearch[param_2],
        };
        this.search_text = dictSearches[param_1];
      }
    },

    _changeModeToStockMarket: function(){
      let success = (response) => {this.modeOfStocks = response;};
      let url = "/api/v1/stock-finder/change-search-mode/stock-market";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('231', 'Error cambiando modo de búsqueda.');
      const apiCallConfig = {loadingOverlay: false, displayErrors: false};

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);

    },
    
    handleViewSwitch() {
      // Show loading screen
      this.isViewSwitching = true;
      
      // Cancel any existing timeout
      if (this.viewSwitchTimeout) {
        clearTimeout(this.viewSwitchTimeout);
      }
      
      // Set a timeout to finish loading if it takes too long
      this.viewSwitchTimeout = setTimeout(() => {
        this.finishViewSwitchLoading();
      }, 2000);
      
      // Reset loading states for new view
      this.isIframeLoading = true;
      this.areStocksLoaded = false;
    },
    
    finishViewSwitchLoading() {
      if (this.viewSwitchTimeout) {
        clearTimeout(this.viewSwitchTimeout);
        this.viewSwitchTimeout = null;
      }
      
      if (this.$refs.viewSwitchLoader) {
        this.$refs.viewSwitchLoader.finishLoading();
      }
    },
    
    handleViewSwitchLoaded() {
      this.isViewSwitching = false;
    },
  },
}
</script>


<style lang="scss">
.stockchart-chart {
    height: calc(89vh);
    position:relative;
    overflow:hidden;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    background-color: var(--color-custom-stockchart-background);
}

@media screen and (max-height: 800px) {
  .stockchart-chart{
    margin-right: 21px;
  }
}

.stockchart{
    height: 100%;
    overflow:hidden;
}
#iframe, #mobile-iframe {
    position: relative;
    z-index: 2;
    transition: opacity 0.5s ease-in-out;
}

/* Iframe loading/loaded states for fade effect */
.iframe-loading {
  opacity: 0;
}

.iframe-loaded {
  opacity: 1;
}

.title-searches{
    background-color: var(--color-custom-search-bar);
    text-align: center;
    height: 40px;
    margin-left: 82px !important;
    margin-right: 82px !important;
  }

  .close-searches{
    font-size: 16px;
    right: 30px;
    position: relative;
    margin-top: 10px;
    color: white;
    float: right;
  }

  .search-text{
    margin-top: 30px !important;
    line-height: 40px !important;
    color: white;
  }

  .chart-with-search-tittle{
    height: 94% !important;
  }

  #fullscreen-button-container-all-pages{
    display: none;
  }
  .banner-big-bank{
    position: absolute;
    width: 20%;
    float: right;
    z-index: 3;
    right: 7%;
  }

  .iframe-loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-custom-stockchart-background);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .wave {
    width: 5px;
    height: 100px;
    background: linear-gradient(45deg, var(--primary-light, #4E6E82), var(--primary, #245275));
    margin: 10px;
    animation: wave 1s ease-in-out infinite;
    border-radius: 20px;
  }

  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }

  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }

  @keyframes wave {
    0% {
      transform: scale(0.3);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.3);
    }
  }

  .full-screen-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: var(--color-background, #fff);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .vanilla-input-container {
    margin: 6px 0 10px 6px;
    width: 15%;
  }
  
  .vanilla-ticker-input {
    width: 100%;
    height: 40px;
    padding: 8px 12px;
    border: 1px solid var(--color-border, #ccc);
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s;
    background-color: var(--color-input-background, #fff);
    color: var(--color-text, #333);
  }
  
  .vanilla-ticker-input:focus {
    border-color: var(--primary, #245275);
    box-shadow: 0 0 0 2px rgba(36, 82, 117, 0.2);
  }
</style>